import consumer from "./consumer"

consumer.subscriptions.create({ channel: "PredictDocumentAsyncChannel" }, {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    const statusElement = document.querySelector(`#job-status-${data.job_id}`);
    if (statusElement) {
      if (data.status === "completed") {
        statusElement.classList.remove('pending');
        $(statusElement).find('.status_complete').removeClass('hidden')
        $(statusElement).find('.status_waiting').addClass('hidden')
        statusElement.classList.add('complate');
        statusElement.querySelector('p').innerText = `${data.filename}`;
      }
    }
  },
});
